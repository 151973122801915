// .loader {
//   width: 60px;
//   aspect-ratio: 2;
//   --_g: no-repeat
//     radial-gradient(circle closest-side, var(--loader-color-light) 90%, #0000);
//   --_g-light: no-repeat
//     radial-gradient(circle closest-side, var(--loader-color-normal) 90%, #0000);
//   background: var(--_g) 0% 50%, var(--_g-light) 50% 50%, var(--_g) 100% 50%;
//   background-size: calc(100% / 3) 50%;
//   animation: l3 1s infinite linear;
// }
// @keyframes l3 {
//   20% {
//     background-position: 0% 0%, 50% 50%, 100% 50%;
//   }
//   40% {
//     background-position: 0% 100%, 50% 0%, 100% 50%;
//   }
//   60% {
//     background-position: 0% 50%, 50% 100%, 100% 0%;
//   }
//   80% {
//     background-position: 0% 50%, 50% 50%, 100% 100%;
//   }
// }

/* HTML: <div class="loader"></div> */
.loader {
  width: 25px;
  aspect-ratio: 1;
  --_c: no-repeat
    radial-gradient(farthest-side, var(--loader-color-normal) 92%, #0000);
  background: var(--_c) top, var(--_c) left, var(--_c) right, var(--_c) bottom;
  background-size: 6px 6px;
  animation: l7 1s infinite;
}
@keyframes l7 {
  to {
    transform: rotate(0.5turn);
  }
}
